import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "submit" ]

  connect() {
    this.files = []
    this.hiddenInput = document.getElementById("hidden-input")
    this.gallery = document.getElementById("attachments")
    this.cancelButton = document.getElementById("cancel")
    this.fileTempl = document.getElementById("file-template")
    this.errorMessage = document.getElementById("upload-limits")

    if (document.getElementById("button") !== null) {
      document.getElementById("button").onclick = (ev) => {
        ev.preventDefault();
        this.hiddenInput.click();
      }
    }

    document.documentElement.style.setProperty("--chat-form-height", "90px")
  }

  clear() {
    this.inputTarget.value = ""

    var event = new KeyboardEvent('keydown', {
      key: 'Backspace',
      code: 'Backspace',
      keyCode: 8,
      which: 8,
    })

    this.inputTarget.dispatchEvent(event)
    this.cancelButton.classList.add("hidden")

    this.cancel()
  }

  cancel(event) {
    if (event) {
      event.preventDefault()
    }

    let hiddenAttachments = document.querySelectorAll("[name='message[attachments][]'][value]")
    hiddenAttachments.forEach((e) => {
      e.remove()
    })

    if (this.cancelButton !== null) {
      this.cancelButton.classList.add("hidden")
    }
    this.hiddenInput.value = null
    this.hiddenInput.removeAttribute("disabled")

    this.gallery.querySelectorAll('div.attachment').forEach((e) => {
      e.remove()
    })

    this.files = {}

    this.gallery.classList.add("hidden")

    document.documentElement.style.setProperty("--chat-form-height", "90px")

    this.enable()
  }

  attachmentsChange(e) {
    let valid = true;
    for (const file of e.target.files) {
      valid = valid && this.addFile(this.gallery, file)
    }

    if (valid) {
      this.enable()
    }
    else {
      e.target.value = null
      this.cancel()
    }
  }

  addFile(target, file) {
    this.errorMessage.classList.add("hidden")
    const maxAllowedSize = 50 * 1024 * 1024;
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain"
    ]

    if (file.size > maxAllowedSize || !allowedTypes.includes(file.type)) {
      this.errorMessage.classList.remove("hidden")
      return;
    }

    if (this.cancelButton !== null) {
      this.cancelButton.classList.remove("hidden")
    }

    const isImage = file.type.match("image.*"), objectURL = URL.createObjectURL(file)
    const clone = this.fileTempl.content.cloneNode(true)

    clone.querySelector(".name").textContent = file.name
    isImage &&
      Object.assign(clone.querySelector("img"), {
        src: objectURL,
        alt: file.name
      });

    target.prepend(clone)

    this.gallery.classList.remove("hidden")

    this.files[objectURL] = file;

    document.documentElement.style.setProperty("--chat-form-height", "222px")
    return true
  }

  enable() {
    let attachedFiles = 0
    this.gallery.querySelectorAll('div.attachment').forEach((e) => {
      attachedFiles += 1
    })

    const regex = /\S/
    if (regex.test(this.inputTarget.value) === false && attachedFiles === 0) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }

  keydown(event) {
    if (event.which == 13 && this.inputTarget.value.length === 0) {
      event.preventDefault()
      return
    }

    if (event.which == 13 && !event.shiftKey) {
      this.element.requestSubmit()
      this.cancelButton.classList.add("hidden")
    }
  }

}
