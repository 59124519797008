import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  targets = ["errorMessage"]
  setup() {
    this.counter = 0;
    this.gallery = document.getElementById("gallery")
    this.overlay = document.getElementById("overlay")    
    this.fileTempl = document.getElementById("file-template")
    this.files = {}
    this.hiddenInput = document.getElementById("hidden-input")
    this.cancelButton = document.getElementById("cancel")
    this.errorMessage = document.getElementById("upload-limits")

    if (document.getElementById("button") !== null) {
      document.getElementById("button").onclick = (ev) => {
        ev.preventDefault();
        this.hiddenInput.click();
      }
    }

    if (this.cancelButton !== null) {
      this.cancelButton.onclick = (ev) => {
        ev.preventDefault();
        this.cancel()
      }
    }
  }

  connect() {
    this.setup()
    document.addEventListener("turbo:morph", this.frameLoaded.bind(this));
  }

  frameLoaded() {
    this.setup()
  }
  
  cancel() {
    let hiddenAttachments = document.querySelectorAll("[name='service_request[attachments][]'][id='service_request_attachments']")
    hiddenAttachments.forEach((e) => {
      e.remove()
    })

    if (this.cancelButton !== null) {
      this.cancelButton.classList.add("hidden")
    }
    this.hiddenInput.value = null
    while (this.gallery.children.length > 0) {
      console.log(this.gallery.lastChild)
      this.gallery.lastChild.remove();
    }
    this.files = {}
  }

  change(e) {
    let valid = true;
    for (const file of e.target.files) {
      valid = valid && this.addFile(this.gallery, file)
    }

    if (valid) {
      this.enable()
    }
    else {
      e.target.value = null
      this.cancel()
    }
  }

  addFile(target, file) {
    this.errorMessage.classList.add("hidden")
    const maxAllowedSize = 50 * 1024 * 1024;
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain"
    ]

    if (file.size > maxAllowedSize || !allowedTypes.includes(file.type)) {
      this.errorMessage.classList.remove("hidden")
      return;
    }
    
    if (this.cancelButton !== null) {
      this.cancelButton.classList.remove("hidden")
    }
    const isImage = file.type.match("image.*"), objectURL = URL.createObjectURL(file)
    const clone = this.fileTempl.content.cloneNode(true)

    clone.querySelector("h1").textContent = file.name
    clone.querySelector("li").id = objectURL
    clone.querySelector(".size").textContent =
      file.size > 1024
        ? file.size > 1048576
          ? Math.round(file.size / 1048576) + " MB"
          : Math.round(file.size / 1024) + " KB"
        : file.size + " b"

    isImage &&
      Object.assign(clone.querySelector("img"), {
        src: objectURL,
        alt: file.name
      });

    target.prepend(clone)

    this.files[objectURL] = file;
    return true;
  }
}
