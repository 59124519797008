import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    console.debug("RELOAD: ", this.element.dataset.url)
  
    if (this.element.tagName !== "TEMPLATE") { return }

    if (this.element.dataset.url !== undefined) {
      const url = this.element.dataset.url
      this.element.remove()
      window.location.href = url
    } else {
      this.element.remove()
      window.location.reload()
    }

  }
}
